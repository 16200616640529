import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button, Box, Dialog } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'features/auth';
import { TIERS } from '@pomp-libs/core';

export const EstheticianNoMembershipPrompt = () => {
  const { isEsthetician, loadedFromServer, user } = useAuth();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [wasCancelled, setWasCancelled] = useState(false);

  const tier = useMemo(() => user?.get('tier'), [user]);
  const subscription = useMemo(() => user?.get('subscription'), [user]);

  useEffect(() => {
    const notOnSubscriptions = location.pathname !== '/profile/subscriptions';
    const notOnboarding = !location.pathname.includes('/onboarding');
    if (wasCancelled) return;

    if (
      isEsthetician &&
      loadedFromServer &&
      notOnSubscriptions &&
      notOnboarding &&
      !subscription && // If they don't have a subscription
      tier === TIERS.NONE // And they haven't been upgraded to a tier by an admin
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [
    isEsthetician,
    loadedFromServer,
    tier,
    location.pathname,
    wasCancelled,
    subscription,
  ]);

  const handleCancel = () => {
    setWasCancelled(true);
    setOpen(false);
  };

  return (
    <Dialog open={open} maxWidth="xs">
      <Box sx={{ textAlign: 'center', p: 2 }}>
        <WarningIcon sx={{ color: 'warning.main' }} fontSize="large" />
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            mt: 2,
            fontFamily: 'Century Old Style Std',
          }}
        >
          There was a problem with your membership
        </Typography>
        <Typography variant="body1" gutterBottom>
          It looks like something went wrong when you tried to change your
          subscription.
        </Typography>
        <Typography variant="body1" gutterBottom sx={{ mt: 1 }}>
          Please email{' '}
          <Link to="mailto:estheticians@pompbeauty.com">
            estheticians@pompbeauty.com
          </Link>{' '}
          - we are happy to help. If you upgrade now, you re-earn commission on
          your orders and re-gain the full Pomp experience.
        </Typography>
        <Button
          component={Link}
          to="/profile/subscriptions"
          variant="contained"
          color="primary"
          sx={{ mt: 2 }}
        >
          Upgrade
        </Button>
        <Button
          onClick={handleCancel}
          variant="text"
          color="secondary"
          sx={{ mt: 2 }}
        >
          Continue
        </Button>
      </Box>
    </Dialog>
  );
};
